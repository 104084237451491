import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ACCOUNT, ADMIN } from './constants/routes';
import { AuthModule } from './modules/auth/auth.module';
import { LayoutModule } from './modules/layout/layout.module';
import { AuthGuard } from './services/guards/auth-guard/auth-guard.guard';
import { LogInGuard } from './services/guards/log-in/log-in.guard';

const routes: Routes = [
  //  { path: '', loadChildren: './modules/auth/auth.module#AuthModule'},
  //  { path: 'admin', loadChildren: './modules/layout/layout.module#LayoutModule'},
  { path: '', redirectTo: ACCOUNT, pathMatch: 'full' },
  { path: ACCOUNT, loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule), canActivate: [LogInGuard] },
  { path: ADMIN, loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
 
  { path: '**', loadChildren: () => import('./modules/layout/not-found/not-found.module').then(m => m.NotFoundModule) },
];

@NgModule({
  imports: [
            RouterModule.forRoot(routes),
         ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
