import { Component, OnInit } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { MessagingService } from './services/messaging/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'airehireme-business-app';
  message: any;

  constructor(
    private $messaing: MessagingService,
    public intercom: Intercom
  ) {

  }

  ngOnInit() {
    this.$messaing.requestPermission('test')
    this.$messaing.receiveMessage();
    this.message = this.$messaing.currentMessage;
    // this.intercom.boot({
    
    //   app_id: 'xskfg8yz',
    //   // app_id: 'gw25hv77',
    //   type: 'contact',
    //   // role: "lead",
    //   // name: 'paul artha', // Full name
    //   // email: 'partha@airhireme.com', // Email address
    //   // id: '60828b7feeca6607372aefaa',
    //   role: "lead",
    //   name: 'shama', // Full name
    //   email: 'soha6504@gmail.com', // Email address
    //   id: '60828b7feeca6607372aefbb',
    
    //   // Supports all optional configuration.
    //   alignment: 'right',
    //   widget: {
    //     'activator': '#intercom'
    //   }
    // })

    // setTimeout(() => {
    //   this.intercom.showNewMessage();
    //   this.intercom.update();
    //   this.intercom.shutdown()
    //   setTimeout(() => this.intercom.boot({
    //     app_id: 'xskfg8yz',
    //     role: "lead",
    //     // app_id: 'gw25hv77',
    //     type: 'contact',
    //    name: 'shama', // Full name
    //     email: 'soha6504@gmail.com', // Email address
    //     id: '60828b7feeca6607372aefbb',
       
    //     // Supports all optional configuration.
    //     alignment: 'left',
    //     widget: {
    //       'activator': '#intercom'
    //     }
    //   }), 1000)
    // }, 1000)

  }


}
