import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { ADMIN_INFO_API, NOTIFICATION__LISTING_API } from './../../constants/api-end-point';
import { DASHBOARD , ABS_ACCOUNT_LOGIN} from '../../constants/absolute-route';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  adminDetail: any;
  categoryAddData : any;
  itemData: any;
  notificationList: any;
  hasNext = false;
  total = 0;
  notifications: Observable<any>;
  loadMoreNotication = new BehaviorSubject(false);
  $notificationList = new BehaviorSubject([]);
  public _notifications: BehaviorSubject<any[]>;
  private dataStore: {
    notifications: any[];
  };
  isCameraSupport: boolean;
  callerDetails: any;
  valid: boolean;
  chatExt = ["jpg", "jpeg", "png"];
  constructor(private _router: Router, private _http: HttpService) { 
    this.dataStore = { notifications: [] };
    this._notifications = <BehaviorSubject<any>>new BehaviorSubject(null);
    this.notifications = this._notifications.asObservable();
  }

  get token() {
    return localStorage.getItem("session_tok");
  }


  fileExists(event: any, info?) {
    let _validFileExtensions;

    if (info == 0) {
      _validFileExtensions = ["jpg", "jpeg", "png"];
    } else if (info == 1) {
      _validFileExtensions = this.chatExt;
    } else {
      /*--more extension----*/
    }
  }
  
  get adminId() {
    return localStorage.getItem("_uid");
  }

  receiverId() {
    let value = localStorage.getItem('id')
    return value;
  }

  socketToken() {
    let value;
    if (localStorage.getItem('socketToken')) {
      value = localStorage.getItem('socketToken');
    }
    return value;
  }

  setChatName(data) {
    localStorage.setItem('chatName', data.firstName);
  }

  getData() {
    return localStorage.getItem('chatName');
  }

  getPreference() {
    return localStorage.getItem('preference')
  }

  userData() {
    let userData = JSON.parse(localStorage.getItem('userData'));
    return userData.firstName + ' ' + userData.lastName
  }

  senderId() {
    let value = localStorage.getItem('userId');
    return value;
  }

  logOut() {
    localStorage.clear();
    setTimeout(() => {
      this.adminDetail = null;
    }, 1000); // this is used for avoid random reflection on side bar if logout
    this._router.navigate([ABS_ACCOUNT_LOGIN]);
  }

  getAdminDetail() {
    return new Promise((resolve, reject) => {
      if (this.adminDetail) {
        resolve(this.adminDetail);
      } else {
        this._http
          .get(ADMIN_INFO_API)
          .toPromise()
          .then(
            response => {
              this.updateAdminDetail(response.data);
              resolve(response.data);
            },
            error => {
              reject(error);
            }
          );
      }
    });
  }

  updateAdminDetail(data: any) {
    this.adminDetail = data;
  }


  getNotificationList(params) {
  if (!this.token) {
    return
  }
  return this._http.get(NOTIFICATION__LISTING_API, params).subscribe(
    data => {
      this.dataStore.notifications = data;      
      this.hasNext = data['data'].nextHit ? true: false;
      this.total = params.total;
      this._notifications.next(Object.assign({}, this.dataStore).notifications);
    },
    err => console.log("Cannot get notification")
  );
     
  }

  loginSuccessfully(response: any) {
    localStorage.setItem("session_tok", response.data.accessToken);
    localStorage.setItem("_uid", response.data._id);
    this._router.navigate([DASHBOARD]);
  }

  deviceDetail(info?: number) {
    /*---1=device_token, 2=deviceId, 3=platform---*/
    switch (info) {
      case 1:
        return "123";
      case 2:
        let deviceId = this.randomDeviceId();
        return deviceId;
      case 3:
        return "3";
      case 4:
        return this.getTimeZone();
    }
  }

  randomDeviceId() {
    return (window.navigator.userAgent.replace(/\D+/g, ''));
  }

  // randomDeviceId(length) {
  //   let result = "";
  //   let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   let charactersLength = characters.length;
  //   for (let i = 0; i < length; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   return result;
  // }

  getTimeZone() {
    let date = new Date();
    let offset = date.getTimezoneOffset() * -1;
    return offset * 60 * 1000;
  }

}
