export const ACCOUNT_ERROR_MESSAGES = {
  NAME_REQ: 'Please enter name',
  EMAIL_REQ: 'Please enter email address',
  PHONE_REQ: 'Please enter Phone address',
  INVALID_EMAIL: 'Please enter a valid email address',
  INVALID_PHONE: 'Please enter a valid Phone Number',
  PASSWORD_REQ: "Please enter password",
  INVALID_PASSWORD: "Password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, minimum 8 and maximum 20 characters",
  NO_SPACE_PASSWORD: "Password can't start or end with a blank space",
}

export const PASSWORD_ERROR_MESSAGES = {
  OLD_PASSWORD_REQ: "Please enter old password",
  INVALID_OLD_PASSWORD: "Old password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, minimum 8 and maximum 20 characters",
  NO_SPACE_OLD_PASSWORD: "Old password can't start or end with a blank space",
  NEW_PASSWORD_REQ: "Please enter new password",
  INVALID_NEW_PASSWORD: "New password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, minimum 8 and maximum 20 characters",
  NO_SPACE_NEW_PASSWORD: "New password can't start or end with a blank space",
  C_PASSWORD_REQ: "Please enter confirm new password",
  INVALID_C_PASSWORD: "Confirm new password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, minimum 8 and maximum 20 characters",
  NO_SPACE_C_PASSWORD: "Confirm new password can't start or end with a blank space",
  CONFORM_PASSWORD_MATCH_ERR: "New password and confirm new password not matched",
  OLD_NEW_PASSWORD_MATCH_ERR: "Old & New password can't be the same",
  WRONG_OLD_PASSWORD: "Entered old password was incorrect",
}

export const LISTING_COMMON_MESSAGES = {
  ACTIVE_TITLE: "Unblock Confirmation",
  ACTIVATE_TITLE: "Active Confirmation",
  BLOCK_TITLE: "Block Confirmation",
  INACTIVATE_TITLE: "Inactive Confirmation",
  DELETE_TITLE: "Delete Confirmation",
  ACTIVE_MSG: "Are you sure you want to unblock?",
  ACTIVATE_MSG: "Are you sure you want to activate?",
  BLOCK_MSG: "Are you sure you want to block?",
  INACTIVATE_MSG: "Are you sure you want to inactivate?",
  DELETE_MSG: "Are you sure you want to delete?",
};

export const LOGOUT_POPUP_MESSAGES = {
  TITLE: 'Logout Confirmation',
  MESSAGE: 'Are you sure you want to logout?',
};

export const PROFILE_INTEREST_ERROR_MESSAGES = {
  INTEREST_NAME_REQ: 'Please enter profile interest name',
  INTEREST_CATEGORY: "Please select interest category",
};

export const CATEGORY_ERROR_MESSAGES = {
  CATEGORY_NAME_REQ: 'Please enter category name'
}

export const INVALID_DATE_TIME_ERROR = {
  INVALID_DATE: "Please select valid date",
  INVALID_TIME: "Please select valid time",
}

export const DEFAULT_COUNTRY_CODE = "+84";
export const INVALID_ID_ERROR = 'Invalid ID';
export const DATA_NOT_FOUND = 'Data not found';
export const NOT_UPDATED = "You can't update codes"
export const MAP_LOCATION_ERROR = 'Oops! Something went wrong. Please select location';
export const NO_ACTION = "You can't perform this action";
export const NO_EDIT_ACTION = (pageType = 'class') => `You can't update this ${pageType}`;
export const SOMETHING_WRONG = 'Something went wrong. Please try again';
export const S3_BUCKET_ERROR = 'Network Failure or Sync up your system clock';
export const OFFLINE = 'Connection lost! You are not connected to Internet';
export const ONLINE = 'Back to online';
export const NO_INTERNET = 'No Internet Connection';
export const TIME_OUT = 'Connection timed out. Please retry';
export const INTERNAL_SERVER_ERROR = "Couldn't connect to the server. Please try again";
export const SELECT_VALID_FILE = "Please select Csv file";
export const VALID_CSV_FILE_SIZE = "Please select file of less than 5 MB";
export const VALID_CSV_FILE_HEADER = "Please write valid column header name(Match with sample csv)";
export const CORPORATE_CREATED_MSG = "Corporate created successfully. \n A verification link has been sent to this email address.";


export const MAX_IMAGE_SIZE = 10;
export const MAX_VIDEO_SIZE = 30;
export const MAX_AUDIO_SIZE = 30;

export const CLASS_MEDIA_LIMITS = {
  MAX_POST_MEDIA: 1,
  MAX_POST_VIDEOS: 1
}

export const IMAGE_FORMAT = 'image/jpeg,image/png,image/jpg';
export const VIDEO_FORMAT = 'video/mp4,video/webm,video/ogg';
export const AUDIO_FORMAT = 'audio/wav,audio/mpeg,audio/mp3';
export const DOC_FORMAT = 'application/pdf';


export const POST_MEDIA_ERROR = `Only ${IMAGE_FORMAT} images and ${VIDEO_FORMAT} videos are allowed`;

export const ALL_MEDIA_ERROR = `Only ${IMAGE_FORMAT} images, ${VIDEO_FORMAT} videos and ${DOC_FORMAT} documents are allowed`;

export const invalidImageError = (format = 'jpeg/png') => `Only ${format} images are allowed`;
export const invalidVideoError = (format = 'mp4/webm/ogg') => `Only ${format} videos are allowed`;
export const invalidAudioError = (format = 'mp3/wav/mpeg') => `Only ${format} audios are allowed`;
export const invalidFileSize = (size = 2) => `File size can not be more than ${size} MB`;

export const TIME_ERROR = {
  FUTURE_TIME: 'Please select future time',
  GREATER_TIME: 'End time should be greater than start time',
  SET_MIN_TIME: (time = 30) => `Set ${time} min time Interval from the start time`
}

export const USER_STATUS = [
  { name: "Active", value: "UN_BLOCKED" },
  { name: "Blocked", value: "BLOCKED" }
];

export const STATUS = [
  { name: "Active", value: "UN_BLOCKED" },
  { name: "Inactive", value: "BLOCKED" }
];

export const DATE_TYPES = {
  SHORT_TIME: 'h:mm a',
  MEDIUM_DATE: 'MMM d, yyyy',
  FULL_DATE: 'EE, MMM d, yyyy',
  VALIDITY_TIME: 'MMM d, y - hh:mm:ss a',
  DATE_WITH_TIME: 'MMM d, y - hh:mm a',
}
export const VALIDATION_MESSAGES = {
  "confirm password": {
    matchPassword: "Confirm password is not matched with password",
  },
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const OPERATING_HRS_ERROR = {
  NO_OPERT_HRS: 'Please add operating hours',
  SAME_TIME: "Open and Close time Should not be same",
  INVALID_TIME: 'Please select valid open & close time'
}

export const DAYS_LIST = [
  { name: 'Everyday', value: 'everyday' },
  { name: 'Weekend', value: 'weekend' },
  { name: 'Monday', value: 'monday' },
  { name: 'Tuesday', value: 'tuesday' },
  { name: 'Wednesday', value: 'wednesday' },
  { name: 'Thursday', value: 'thursday' },
  { name: 'Friday', value: 'friday' },
  { name: 'Saturday', value: 'saturday' },
  { name: 'Sunday', value: 'sunday' }
]

export const timeConverter = (date: any, isHour12 = true) => {
  if (!date || typeof (date) != 'object') { return date }
  let time = new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: isHour12
  })
  let hours = Number(time.split(':')[0]);
  let minutes = Number(time.split(':')[1]);
  return (hours * 60 + minutes);
}


export const hoursConverter = (date: any, isHour12 = true) => {
  const hours = date / 60;
  const minutes = date % 60;
  return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), hours, minutes, 0, 0)
}

export const maxImageSize = 10;
export const maxVideoSize = 20;
export const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png"]
export const allowedVideoTypes = ["video/mp4"];
export const errorMessages = {
  [1]: {
    sizeError: `Image size can not be more than ${maxImageSize} mb.`,
    TypeErr: 'Please select an jpg/jpeg/png image format file.'
  },
  [2]: {
    sizeError: `Video size can not be more than ${maxVideoSize} mb.`,
    TypeErr: 'Please select an MP4/WMV/AVI video format file.'
  },
}
export const newVideoSelected = false;
export const loadingMessage = 'Loading...';
export const noRecordMessage = 'Data Not found';