// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_BASE_PATH: 'https://airehirestgapi.appskeeper.in/api/v1/',
  // API_BASE_PATH: 'https://api.airhireme.com/api/v1/',
  
  API_KEY: "1234", 
  FINGER_PRINT: 'Basic ' + btoa(`${'airHire'}:${'airHire@123'}`),
  mapsApiKey: 'AIzaSyC445P-0GdRNz_li2hPGjYLzHzFokCpj68', 
  config: { 
    AWS_ACCESS_KEY: 'AKIAVCGZ5XVJ7JHQ7YSW',
    AWS_SECRET_KEY: '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',
    REGION: 'ap-southeast-2',
    BUCKET_NAME: 'airhire-bucket'
  }, 
  firebaseConfig: {
    apiKey: "AIzaSyCw8get-JHZhL_-QvphWqyjpuprqL55WGI",
    authDomain: "airhireme-17d58.firebaseapp.com",
    projectId: "airhireme-17d58",
    storageBucket: "airhireme-17d58.appspot.com",
    messagingSenderId: "349822322977",
    appId: "1:349822322977:web:e00bbb8a50d0cc5967d660",
    measurementId: "G-B1D5SRFV8X"

  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
