/**
 * @ABS === ABSOLUTE
 */

import * as routes from './routes';

export const DASHBOARD = `/${routes.ADMIN}/${routes.DASHBOARD}`;
export const DASHBOARD_REPORT = `/${routes.ADMIN}/${routes.DASHBOARD}/report`;
export const VERIFY_BUSINESS = `/${routes.ADMIN}/${routes.VERIFY_BUSINESS}`;

export const CONTENT = `/${routes.ADMIN}/${routes.CONTENT}`;

export const ABS_LOGIN = `/${routes.LOGIN}`;
export const ABS_ACCOUNT_LOGIN = `/${routes.ACCOUNT}/${routes.LOGIN}`;

export const FORGOT_PASSWORD = `/${routes.ACCOUNT}/${routes.FORGOT_PASSWORD}`;
export const ABS_RESET_PASSWORD = `/${routes.ACCOUNT}/${routes.RESET_PASSWORD}`;
export const ABS_VERIFY_OTP = `/${routes.ACCOUNT}/${routes.VERIFY_OTP}`;

export const ABS_ADMIN_PROFILE = `/${routes.ADMIN}/${routes.PROFILE}`;
export const ABS_ADMIN_PROFILE_EDIT = `/${routes.ADMIN}/${routes.EDIT}`;

export const ABS_ADMIN_CHANGE_PASSWORD = `/${routes.ADMIN}/${routes.CHANGE_PASSWORD}`;
export const ABS_ADMIN_VERIFY_EMAIL = `/${routes.ADMIN}/${routes.VERIFY_EMAIL}`;
export const ABS_ADMIN_NEW_PASSWORD = `/${routes.ADMIN}/${routes.NEW_PASSWORD}`;
export const ABS_ACCOUNT_FORGOT_PASSWORD = `/${routes.ACCOUNT}/${routes.FORGOT_PASSWORD}`;
export const ABS_ACCOUNT_SETTINGS = `/${routes.ADMIN}/${routes.SETTINGS}`;

export const ABS_USERS = `/${routes.ADMIN}/${routes.USERS}`;
export const BUSINESSES = `/${routes.ADMIN}/${routes.BUSINESSES}`;
export const ABS_ADD_BUSINESS = `/${routes.ADMIN}/${routes.ADD_BUSINESS}`;
export const ABS_ADD_BUSINESS_SIGNUP = `/${routes.ADMIN}/${routes.ADD_BUSINESS}/${routes.SIGNUP}`;
export const ABS_ADD_BUSINESS_DETAILS = `/${routes.ADMIN}/${routes.ADD_BUSINESS}/${routes.DETAILS}`;
export const INDIVIDUALS = `/${routes.ADMIN}/${routes.INDIVIDUALS}`;
export const ABS_USERS_DETAILS = `/${routes.USERS}/${routes.DETAILS}`;
export const ABS_VERIFICATION_REQUIRED = `/${routes.ADMIN}/${routes.VERIFICATION_REQUIRED}`;
export const ABS_LAST_HIRE= `/${routes.ADMIN}/${routes.LAST_HIRE}`;
export const ABS_AWAITING_REVENUE = `/${routes.ADMIN}/${routes.AWAITING_REVENUE}`;

export const ABS_DID_YOU_KNOW = `/${routes.ADMIN}/${routes.DID_YOU_KNOW}`;
export const ABS_DID_YOU_KNOW_ADD = `/${routes.ADMIN}/${routes.DID_YOU_KNOW}/${routes.ADD}`;
export const ABS_QUESTION_ANSWER = `/${routes.ADMIN}/${routes.QUESTION_ANSWER}`;
export const ABS_QUESTION_ANSWER_ADD = `/${routes.ADMIN}/${routes.QUESTION_ANSWER}/${routes.ADD}`;
export const ABS_CHAT = `/${routes.ADMIN}/${routes.CHAT}`;


export const INTERESTS = `/${routes.ADMIN}/${routes.INTERESTS}`;

export const ABS_CATEGORY_TAGS = `/${routes.ADMIN}/${routes.CATEGORY_TAGS}`;
export const ABS_CATEGORY_TAGS_ADD = `/${routes.ADMIN}/${routes.CATEGORY_TAGS}/${routes.ADD}`;


export const ABS_CATEGORY = `/${routes.ADMIN}/${routes.CATEGORY}`;
export const ABS_CATEGORY_DETAILS = `/${routes.ADMIN}/${routes.CATEGORY}`;
export const ABS_CATEGORY_ADD = `/${routes.ADMIN}/${routes.CATEGORY}/${routes.ADD}`;
export const ABS_CATEGORY_LISTING = `/${routes.ADMIN}/${routes.CATEGORY}/${routes.USERS_LIST}`;

export const ABS_ARTICLES = `/${routes.ADMIN}/${routes.ARTICLES}`;
export const ABS_ARTICLES_LISTING = `/${routes.ADMIN}/${routes.ARTICLES}/${routes.USERS_LIST}`;
export const ABS_ARTICLES_ADD = `/${routes.ADMIN}/${routes.ARTICLES}/${routes.ADD}`;

export const ABS_BUNNERS = `/${routes.ADMIN}/${routes.BUNNERS}`;
export const ABS_BUNNERS_LISTING = `/${routes.ADMIN}/${routes.BUNNERS}/${routes.USERS_LIST}`;
export const ABS_BUNNERS_ADD = `/${routes.ADMIN}/${routes.BUNNERS}/${routes.ADD}`;

export const ABS_INTERESTS = `/${routes.INTERESTS}`;
export const ABS_INTERESTS_DETAILS = `/${routes.INTERESTS}/${routes.DETAILS}`;

export const ABS_INSURANCE = `/${routes.ADMIN}/${routes.INSURANCE_CLAIM}`;
export const ABS_NOTIFICATION= `/${routes.ADMIN}/${routes.NOTIFICATION}`;

export const ABS_DISPUTES_RESOLUTION = `/${routes.ADMIN}/${routes.DISPUTES_RESOLUTION}`;



export const ABS_INBOX = `/${routes.ADMIN}/${routes.INBOX}`;
export const ABS_INBOX_STARRED = `/${routes.ADMIN}/${routes.INBOX}/${routes.STARRED}`;
export const ABS_INBOX_SENT = `/${routes.ADMIN}/${routes.INBOX}/${routes.SENT}`;
export const ABS_INBOX_DRAFT = `/${routes.ADMIN}/${routes.INBOX}/${routes.DRAFT}`;
export const ABS_INBOX_TRASH = `/${routes.ADMIN}/${routes.INBOX}/${routes.TRASH}`;
export const ABS_INBOX_LIST = `/${routes.ADMIN}/${routes.INBOX}/${routes.USERS_LIST}`;
export const ABS_INBOX_COMPOSE = `/${routes.ADMIN}/${routes.INBOX}/${routes.ADD}`;

export const ABS_REVIEW = `/${routes.ADMIN}/${routes.REVIEW}`;
export const ABS_REVIEW_ADD = `/${routes.ADMIN}/${routes.REVIEW}/${routes.ADD}`;

export const ABS_ACTIVITIES_DETAILS = `/${routes.ADMIN}/${routes.ACTIVITIES}/${routes.DETAILS}`;
export const ABS_ACTIVITIES_ITEM_LIST = `/${routes.ADMIN}/${routes.ACTIVITIES}`;
export const ABS_ACTIVITIES_ITEM_ADD = `/${routes.ADMIN}/${routes.ACTIVITIES}/${routes.ADD}`;
export const ABS_ACTIVITIES_NEXT = `/${routes.ADMIN}/${routes.ACTIVITIES}/${routes.ACTIVITIES_NEXT}`;
export const ABS_ACTIVITIES_FINAL = `/${routes.ADMIN}/${routes.ACTIVITIES}/${routes.ACTIVITIES_FINAL}`;
export const ABS_ACTIVITIES_FIRST = `/${routes.ADMIN}/${routes.ACTIVITIES}/${routes.ADD}/${routes.FIRST}`;
export const ABS_CANCEL_BOOKING= `/${routes.ADMIN}/${routes.ACTIVITIES}/${routes.CANCEL_BOOKING}`;


