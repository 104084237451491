// export const USER = 'user';
export const PARTICIPANT = 'participant';
export const SUPPORTER = 'supporter';
export const USERS_LIST = 'list';
export const LOGIN = 'login';
export const SIGNUP = 'signup';
export const PROFILE = 'profile';
export const FIRST = 'step-one';

export const ACCOUNT = 'account';

export const FORGOT_PASSWORD = 'forgot-password';
export const VERIFY_OTP = 'verify-otp';
export const VERIFY_BUSINESS = 'verify-business';
export const ADD_BUSINESS = 'add-business';

export const RESET_PASSWORD = 'reset-password';
export const VERIFY_EMAIL = 'email-verify';
export const  NEW_PASSWORD= 'new-password';

export const CHANGE_PASSWORD = 'change-password';

export const ADMIN = 'admin';

export const DASHBOARD = 'dashboard';

export const CONTENT = 'content-management';

export const EDIT = 'edit';

export const ADD = 'add';

export const DETAILS = 'details';

export const USERS = 'users';
export const BUSINESSES = 'businesses';
export const INDIVIDUALS = 'individual';

export const CATEGORY = 'categories';
export const ARTICLES = 'articles';

export const INTERESTS = 'interests';

export const SETTINGS = 'settings';

export const ACTIVITIES = 'item';
export const ACTIVITIES_NEXT = 'step-two';
export const ACTIVITIES_FINAL = 'step-three';
export const ACTIVITIES_PREVIEW = 'preview';

export const BUNNERS = 'banners';
export const DID_YOU_KNOW = 'did-you-know';
export const QUESTION_ANSWER = 'ques-ans';
export const LAST_HIRE = 'last-hire';
export const AWAITING_REVENUE = 'awaiting-revenue';

export const VERIFICATION_REQUIRED = 'verification-required';

export const INSURANCE_CLAIM = 'insurance-claim';
export const DISPUTES_RESOLUTION = 'disputes-resolution';
export const INBOX = 'inbox';
export const STARRED = 'starred';
export const SENT = 'sent';
export const TRASH = 'trash';
export const DRAFT = 'draft';

export const REVIEW = 'review';
export const NOTIFICATION = 'notification';
export const CHAT = 'chat';
export const CANCEL_BOOKING = 'cancel-booking';
export const CATEGORY_TAGS = 'category-tags';
