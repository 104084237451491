import { ADMIN } from './routes';

const LOCAL_PATH: string = "/assets/json/";
const ADMIN_BASE: string = 'admin';
const USER_BASE: string = 'user';


/**
 * @LOCAL_COUNTRY_CODES_LIST
 */
export const LOCAL_COUNTRY_WITH_CODE = `${LOCAL_PATH}Country.json`;

/**
 * @ACCOUNT_RELATED_END_POINTS
 */

export const LOGIN_API = `${ADMIN_BASE}/login`;
export const LOGOUT_API = `${ADMIN_BASE}/logout`;
export const FORGOT_PASSWORD_API = `${ADMIN_BASE}/forgot-password`;
export const RESET_PASSWORD_API = `${ADMIN_BASE}/reset-password`;
export const VERIFY_OTP_API = `${ADMIN_BASE}/verify-forgot-password-otp`;
export const SEND_OTP_API = `${ADMIN_BASE}/verify`;
export const CHANGE_PASSWORD_API = `${ADMIN_BASE}/change-password`;
export const ADMIN_INFO_API = `${ADMIN_BASE}/info`;
export const ADMIN_EMAIL_VERIFICATION_API = `${ADMIN_BASE}/forgot-change-password`;
export const ADMIN_OTP_VERIFICATION_API = `${ADMIN_BASE}/verify-forgot-change-password-otp`;
export const BUSINESS_SIGNUP_API = `${ADMIN_BASE}/user-business`;

/**
 * @DASHBOARD_RELATED_END_POINTS
 */
export const DASHBOARD_OVERVIEW_API = `${ADMIN_BASE}/dashboard-overview`;
export const DASHBOARD_ITMES_API = `${ADMIN_BASE}/dashboard-items`;
export const DASHBOARD_USERS_API = `${ADMIN_BASE}/dashboard-user`;
export const DASHBOARD_REVENUE_API = `${ADMIN_BASE}/dashboard-revenue`;
export const EXPORT_REPORT_GRAPH_API = `${ADMIN_BASE}/reports`;
export const DASHBOARD_CATEGORY_BASE_GRAPH_API = `${ADMIN_BASE}/dashboard-categoryBased
`;

/**
 * @CONTENT_RELATED_END_POINTS
 */
export const CONTENTS_API = (type) => `content/${type}`;
// export const EDIT_CONTENTS_API = `content`;
export const CONTENTS_API_FAQ = `content`;

/**
 * @USER_RELATED_END_POINTS
 */

export const USERS_API = `${ADMIN_BASE}/user-list`;
export const USER_DETAILS_API = `${ADMIN_BASE}/user-details`;
export const BLOCK_UNBLOCK_USER_API = (id) => `${ADMIN}/block-user/${id}`;
export const BLOCK_UNBLOCK_API = (id) => `${ADMIN}/users-status/{userId}`;
export const APPROVE_DECLINE_USER_API = `${USER_BASE}/approved-declined`;
export const USERS_REPORT_API = `${USER_BASE}/export`;
export const USERS_ABOUT_API = `${ADMIN_BASE}/users`;
export const USERS_LISTINGS_API = `${ADMIN_BASE}/user-items`;
export const VERIFY_BUSSINESS_API = (id) => `${ADMIN_BASE}/users-status/${id}`;

/**
 * @INTEREST_RELATED_END_POINTS
 */
export const INTEREST_API = `interests`;
export const INTEREST_DETAILS_API = (interestId) => `interests/${interestId}`;
export const BLOCK_UNBLOCK_INTEREST_API = `interests`;
export const INTEREST_ADD_API = `interests`;

/**
 * @CATEGORY_RELATED_END_POINTS
 */
export const CATEGORY_API = `${ADMIN_BASE}/categories`;
export const CATEGORY_LIST_API = `${ADMIN_BASE}/categories`;
export const CATEGORY_ADD_API = `${ADMIN_BASE}/categories`;
export const CATEGORY_DETAILS_API = (id) => `${ADMIN_BASE}/categories/${id}`;
/**
 * @ADVENTURE_RELATED_END_POINTS
 */
export const ADVENTURE_API = `${ADMIN_BASE}/adventure/stories`;
export const ADVENTURE_STORIES_ADD_API = `${ADMIN_BASE}/adventure/stories`;
export const ADVENTURE_STORIES_DETAILS_API = (itemId) => `${ADMIN_BASE}/adventure/stories/${itemId}`;
/**
 * @ITEM_RELATED_END_POINTS
 */
export const ITEM_API = `${ADMIN_BASE}/active-items`;
export const ITEM_ADD_API = `${ADMIN_BASE}/items`;
export const ITEM_DETAILS_EDIT_API = (id) => `${ADMIN_BASE}/items/${id}`;
export const ITEM_DELETE_API = (itemId) => `${ADMIN_BASE}/items/${itemId}`;
export const ITEM_DETAILS_API = (itemId, type) => `${ADMIN_BASE}/items/${itemId}/${type}`;

/**
 * @SETTING_RELATED_END_POINTS
 */
export const SETTING_ADD_API = `${ADMIN_BASE}/setting`;
export const SETTING_DETIALS_API = `${ADMIN_BASE}/setting`;
export const SETTING_EDIT_API = (id) => `${ADMIN_BASE}/user-comission/${id}`;
export const SETTING_PATCH_EDIT_API = `${ADMIN_BASE}/setting`;

/**
 * @ORDER_BOOKING_RELATED_END_POINTS
 */
export const ORDER_BOOKING__LISTING_API = `${ADMIN_BASE}/booking-order`;
export const CANCEL_ORDER_BOOKING_API = `${ADMIN_BASE}/booking/cancel/accept-decline`;
export const EDIT_ORDER_API = (bookingId) => `${ADMIN_BASE}/active-items/${bookingId}`;

/**
 * @REVIEW_TAGS_RELATED_END_POINTS
 */
export const REVIEW_TAGS__LISTING_API = `${ADMIN_BASE}/review-tag`;
export const REVIEW_TAGS__DETAILS_API = (id) => `${ADMIN_BASE}/review/${id}`;

/**
 * @DISPUTES_RESOLUTION_RELATED_END_POINTS
 */
export const DISPUTES_RESOLUTION__LISTING_API = `${ADMIN_BASE}/dispute`;
export const DISPUTES_RESOLUTION__DETAILS_API = (id) => `${ADMIN_BASE}/dispute/${id}`;

/**
 * @NOTIFICATION_RELATED_END_POINTS
 */
export const NOTIFICATION__LISTING_API = `${ADMIN_BASE}/notification`;

/**
 * @NOTIFICATION_RELATED_END_POINTS
 */
export const  CHAT_NOTIFICATION = 'mobile/notification';
export const  CHAT_SUPPORT_COUNT= 'user/clearChatNotification';

/**
 * @DID_YOU_KNOW_RELATED_END_POINTS
 */
export const  DID_YOU_KNOW_LIST = `${ADMIN_BASE}/envFactQue`;
export const  DID_YOU_KNOW_ADD= `${ADMIN_BASE}/env-que`;
export const  DID_YOU_KNOW_EDIT =  (id) => `${ADMIN_BASE}/env/${id}`;

/**
 * @INBOX_RELATED_END_POINTS
 */
export const INBOX__LISTING_API = `${USER_BASE}/inbox`;
export const INBOX__COMPOSE_API = `${ADMIN_BASE}/inbox-sendEmail`;
export const INBOX__EMAIL_SEARCH_API = `${ADMIN_BASE}/user/email-search`;
export const DELETE_INBOX_API = `${USER_BASE}/inbox`;
export const STAR_INBOX_API = `${USER_BASE}/inbox`;
export const INBOX_DETAILS_API = (messageThreadId) => `${USER_BASE}/inbox/${messageThreadId}`;

/**
 * @INSURANCE_CLAIM_RELATED_END_POINTS
 */
export const INSURANCE_CLAIM__LISTING_API = `${ADMIN_BASE}/insurance/claim`;
export const INSURANCE_CLAIM__DETAILS_API = (id) => `${ADMIN_BASE}/insurance/claim/${id}`;
export const INSURANCE_CLAIM_SOLVED_API = `${ADMIN_BASE}/insurance/claim-status`;

/**
 * @BUNNERS_RELATED_END_POINTS
 */
export const BUNNERS__LISTING_API = `${ADMIN_BASE}/banner`;
export const BUNNERS__DETAILS_API = (id) => `${ADMIN_BASE}/banner/${id}`;
export const BUNNERS_CHECKED_API = (bannerId) => `${ADMIN_BASE}/banner/status/${bannerId}`;
export const BUNNERS__DELETE_API = (id, status) => `${ADMIN_BASE}/banner/${id}/${status}`;
export const PAYMENT_METHODS_API = (userId) => `${ADMIN_BASE}/${USER_BASE}/payment-methods/${userId}`;
export const PAYMENT_METHODS_HISTORY_API = `${ADMIN_BASE}/${USER_BASE}/payment-history`;



